/* src/PasswordScreen.css */

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
  }
  
  .password-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .password-form-container {
    background: #fff;
    padding: 2rem 3rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #333;
  }
  
  .password-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .password-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .submit-button {
    padding: 0.75rem 1.5rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .submit-button:active {
    background-color: #004085;
    transform: translateY(0);
  }
  
  .error-message {
    margin-top: 1rem;
    color: red;
    font-size: 0.9rem;
  }
  