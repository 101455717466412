* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto Serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.sentence-flow {
  background-color: #31769B;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
}

.sentence-flow h1 {
  text-align: center;
  color: white;
}

.sentence-flow .sentence-flow-text {
  color: #00A14B;
}

.problem-mapping {
  background: #241E66;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
}

.problem-mapping h1 {
  text-align: center;
  color: white;
}

.problem-mapping .problem {
  color: red;
}

.problem-mapping .solution {
  color: green;
}

.button {
  padding: 10px 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #000000;
  cursor: pointer;
}

.problem-icon {
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 768px) {
  body {
      flex-direction: column;
  }

  .sentence-flow {
      width: 100%;
      gap: 10px;
  }

  .problem-mapping {
      width: 100%;
      gap: 10px;
  }

  .problem-icon {
      width: 80px;
      height: 80px;
  }

  * {
      font-size: 20px;
      line-height: 30px;
  }
}